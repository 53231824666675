var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.notView
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "18px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.addRow },
            },
            [_vm._v("添加一行")]
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData || [], "row-key": "keys", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50px" },
          }),
          _c("el-table-column", {
            attrs: { prop: "detailName", width: "200", label: "档级名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticClass: "cell-input",
                      attrs: { placeholder: "请输入", disabled: !_vm.notView },
                      model: {
                        value: scope.row.detailName,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "detailName", $$v)
                        },
                        expression: "scope.row.detailName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "minScore",
              "min-width": "80",
              label: "最小分值（包含）",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      staticClass: "cell-input",
                      attrs: { min: 0, disabled: !_vm.notView },
                      model: {
                        value: scope.row.minScore,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "minScore", $$v)
                        },
                        expression: "scope.row.minScore",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "maxScore",
              "min-width": "80",
              label: "最大分值（不包含）",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input-number", {
                      staticClass: "cell-input",
                      attrs: { min: 0, disabled: !_vm.notView },
                      model: {
                        value: scope.row.maxScore,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "maxScore", $$v)
                        },
                        expression: "scope.row.maxScore",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "interestRuleList",
              "min-width": "200",
              label: "权益管理规则",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticClass: "cell-input",
                      attrs: {
                        value:
                          scope.row.interestRuleList &&
                          scope.row.interestRuleList.length
                            ? scope.row.interestRuleList
                                .map(function (item) {
                                  return item.interestRuleName
                                })
                                .join(",")
                            : "",
                        placeholder: "请选择",
                        readonly: "",
                        disabled: !_vm.notView,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showRuleModal(scope.$index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm.notView
            ? _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3000837700
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }