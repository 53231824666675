<template>
  <div>
    <el-button
      style="margin-bottom: 18px"
      type="primary"
      @click="addRow"
      size="small"
      v-if="notView"
      >添加一行</el-button
    >
    <el-table :data="tableData || []" style="width: 100%" row-key="keys" border>
      <el-table-column type="index" label="序号" width="50px" />
      <el-table-column prop="detailName" width="200" label="档级名称">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.detailName"
            placeholder="请输入"
            class="cell-input"
            :disabled="!notView"
          />
        </template>
      </el-table-column>
      <el-table-column prop="minScore" min-width="80" label="最小分值（包含）">
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.minScore"
            :min="0"
            class="cell-input"
            :disabled="!notView"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="maxScore"
        min-width="80"
        label="最大分值（不包含）"
      >
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.maxScore"
            :min="0"
            class="cell-input"
            :disabled="!notView"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="interestRuleList"
        min-width="200"
        label="权益管理规则"
      >
        <template slot-scope="scope">
          <el-input
            :value="
              scope.row.interestRuleList && scope.row.interestRuleList.length
                ? scope.row.interestRuleList
                    .map((item) => item.interestRuleName)
                    .join(',')
                : ''
            "
            class="cell-input"
            placeholder="请选择"
            readonly
            :disabled="!notView"
            @click.native="showRuleModal(scope.$index)"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="notView" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    SelectConfig,
  },
  props: {
    notView: Boolean,
  },
  data() {
    return {
      modalConfig: {
        title: '选择字段',
        visible: false,
        showFooter: true,
      },
      dictCodeList: null,
      fieldTable: [],
      childrenList: [],
      inType: '',
      inKey: '',
      rowForm: {},
      isShow: '',
      scopeData: null,
      tableData: [],
      currIndex: -1,
    };
  },
  methods: {
    showRuleModal(currIndex) {
      console.log(currIndex);
      this.currIndex = currIndex;
      const params = {
        data: this.tableData[currIndex].interestRuleList || [],
        // selectionList: [],
        parentCode: 'CRM20250214000000005',
        functionCode: 'interest_rule_select',
        idKey: 'interestRuleCode',
        requestType: 'GET',
        noReset: true,
        paramData: {
          enableStatus: '009',
        },
      };

      this.$refs.selectConfig.openSelectModal(params);
    },
    addRow() {
      this.tableData.push({
        detailName: '',
        minScore: '',
        maxScore: '',
        interestRuleList: [],
      });
    },
    handleDelete(index, row) {
      this.tableData.splice(index, 1);
    },
    // 选择回调
    onGetSelect(val) {
      // this.$emit('change', val);
      console.log(val);

      if (val && val.length) {
        this.tableData[this.currIndex].interestRuleList = val;
      }
    },
  },
};
</script>

<style>
</style>
