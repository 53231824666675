import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
// import addForm from './addForm.vue';
import LevelSetting from './LevelSetting.vue';
// import SettlementCycle from './SettlementCycle.vue';

// formCreate.component('addForm', addForm);
formCreate.component('LevelSetting', LevelSetting);
// formCreate.component('SettlementCycle', SettlementCycle);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'rading_engine_form',
    };
  },
  async created() {
    await this.getFormRule('rading_engine_form');
  },
  methods: {
    // 设置规则
    setRule(item) {
      // console.log('item', item);
      const that = this;
      if (item.field === 'productScoreRuleList') {
        item.restful = '/cps/v1/fxhProductScoreRule/findByConditions';
        item.restfulParams = {
          enableStatus: '009',
          delFlag: '009',
        };
        // item.options = [];
        item.props = {
          ...item.props,
        };
        item.optionsKey = { label: 'productScoreRuleName', value: 'productScoreRuleCode' };
        // item.on = {
        //   ...item.on,
        //   change: (e) => {
        //     this.setValue({
        //       settlementCycle: e,
        //     });
        //   },
        // };
      } else if (item.field === 'settlementCycle') {
        item.restful = '/cps/v1/fxhCycleConfig/findByConditions';
        item.restfulParams = {
          enableStatus: '009',
          delFlag: '009',
        };
        // item.options = [];
        item.props = {
          ...item.props,
        };
        item.optionsKey = { label: 'cycleName', value: 'cycleCode' };
      } else if (item.field === 'discipline') {
        item.restful = '/cps/regulatoryrule/list';
        item.restfulParams = {
          enableStatus: '009',
          delFlag: '009',
        };
        // item.options = [];
        item.props = {
          ...item.props,
        };
        item.optionsKey = { label: 'regulatoryRuleName', value: 'regulatoryRuleCode' };
      } else if (item.field === 'levelRule') {
        item.props = {
          ...item.props,
          limit: 1,
          fileMsg: '只能上传jpg/png文件',
          accept: '.png,.jpg,.jpeg',
        };
      } else if (item.field === 'LevelSetting') {
        item.props = {
          ...item.props,
          notView: this.formConfig.code !== 'view',
        };
      }

      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;
      // if (type === 'add') {
      //   this.hiddenFields(true, ['productCode']);
      // }
      if (type === 'edit' || type === 'view') {
        // this.setValue(this.formConfig.row);
        request.get('/cps/fxh/terminalConfigurationRuleController/findById', {
          id,
        }).then((res) => {
          if (res.success) {
            console.log(res, 'res');
            const { result } = res;
            const levelSettingEl = this.fApi.el('LevelSetting');
            const tableData = result.details || [];
            tableData.forEach((item) => {
              item.interestRuleList = item.interestRuleList && item.interestRuleList.map((list) => ({
                interestRuleCode: list.ruleCode,
                interestRuleName: list.ruleName,
              }));
            });
            levelSettingEl.tableData = tableData;
            this.setValue({
              ...result,
              settlementCycle: result.cycleCode,
              productScoreRuleList: result.productScoreRuleList ? result.productScoreRuleList.map((item) => item.ruleCode) : [],
              discipline: result.disciplineCode,
            });
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        console.log(formData, 'formData');
        const settlementCycleRule = this.getRule('settlementCycle');
        console.log(settlementCycleRule, 'settlementCycleRule');
        const productScoreRuleListRule = this.getRule('productScoreRuleList');
        console.log(productScoreRuleListRule, 'productScoreRuleListRule');
        const disciplineRule = this.getRule('discipline');
        console.log(disciplineRule, 'disciplineRule');
        const levelSettingEl = this.fApi.el('LevelSetting');
        console.log(levelSettingEl, 'levelSettingEl');
        const findRes = settlementCycleRule.options.find((list) => list.cycleCode === formData.settlementCycle) || {};
        const filterRes = productScoreRuleListRule.options.filter((list) => formData.productScoreRuleList.includes(list.productScoreRuleCode)).map((list) => ({
          ruleName: list.productScoreRuleName,
          ruleCode: list.productScoreRuleCode,
        }));
        const disciplineDta = disciplineRule.options.find((list) => list.regulatoryRuleCode === formData.discipline) || {};
        const tableData = levelSettingEl.tableData ? JSON.parse(JSON.stringify(levelSettingEl.tableData)) : [];
        tableData.forEach((item) => {
          item.interestRuleList = item.interestRuleList.map((list) => ({
            ruleCode: list.interestRuleCode,
            ruleName: list.interestRuleName,
          }));
        });
        const params = {
          ...formData,
          beginTime: findRes.beginTime,
          endTime: findRes.endTime,
          cycleName: findRes.cycleName,
          cycleCode: findRes.cycleCode,
          disciplineName: disciplineDta.regulatoryRuleName,
          disciplineCode: disciplineDta.regulatoryRuleCode,
          productScoreRuleList: filterRes,
          details: tableData,
        };
        delete params.discipline;

        console.log(params, 'params');

        // 默认新增
        const url = '/cps/fxh/terminalConfigurationRuleController/saveTerminalConfigurationRule';
        // 有id  就是编辑
        console.log('🚀 ~ submit ~ this.formConfig.id:', this.formConfig.id);
        if (this.formConfig.id) {
          params.id = this.formConfig.id;
        }
        console.log(params, 'params');
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
